import React from "react";

const AboutMoreModal = () => {
  return (
    <div
      id="about-us-details"
      className="modal fade"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title bg-dark">&nbsp;&nbsp;More About Me&nbsp;&nbsp;</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body p-5">
            <p>
              Passionate about leveraging technology to drive meaningful impact and fostering a culture of growth and excellence within teams.
              I combine my passion for leading people and developing business ideas with advanced
              development technologies.
              {/* {" "}<strong className="fw-600">My clients</strong> have procured
              exceptional results and happiness while working with me. */}
            </p>
            <h5 className="text-primary mt-4">Mission</h5>
            <p>
              To lead by example, driving technological innovation, fostering collaboration, and empowering teams to exceed expectations, ultimately making a lasting impact on the world.
            </p>
            <h5 className="text-primary mt-4">Vision</h5>
            <p>
              To create a future where technology is a force for positive change, where innovation is limitless, and where empowered teams work together to solve the world's most pressing challenges.
            </p>
            <h5 className="text-primary mt-4">Goal</h5>
            <p>
              To continuously push the boundaries of what's possible in the realm of technology, inspiring others to join me in the pursuit of excellence, and leaving a legacy of innovation and progress for generations to come.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutMoreModal;
