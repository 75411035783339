import React from "react";

const TermsAndConditionModal = () => {
  return (
    <div
      id="terms-policy"
      className="modal fade"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Terms &amp; Policy</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body p-4">
            <p>
              At Andy Consulting & Co., we are committed to protecting the privacy and security of your personal information. This Privacy Policy outlines how we collect, use, and safeguard your data when you visit our website or use our services.{" "}
            </p>

            <p>
                Legal Information: Andy Consulting & Co. is formerly known as SHARPCOMPUTING LLC. The entity is legally registered and operative in the state of Wyoming since Jan 22nd, 2022.
            </p>

            <h3 className="mb-3 mt-4">Privacy Policy</h3>
            <h5 className="text-4 mt-4">
              Part I – Information We Collect
            </h5>
            <ol className="lh-lg">
              <li>Personal Information: When you engage with our website or services, we may collect personal information such as your name, email address, phone number, and company details.</li>
              <li>Usage Data: We may also collect non-personal information such as your IP address, browser type, and device information to analyze website usage and improve our services.</li>
            </ol>

            <h5 className="text-4 mt-4">
              Part II – How We Use Your Information
            </h5>
            <p>
              We use your information to provide and maintain our services, communicate with you and respond to your inquiries, personalize your experience, improve our website and services, and comply with legal obligations while protecting our rights.
            </p>

            <h5 className="text-4 mt-4">
              Part III – Data Security
            </h5>
            <p>
              We implement appropriate security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction.
            </p>

            <h5 className="text-4 mt-4">
              Part IV – Third-Party Services
            </h5>
            <p>
              We may use third-party service providers to facilitate our services or analyze website usage. These providers have access to your personal information only to perform specific tasks on our behalf and are obligated not to disclose or use it for any other purpose.
            </p>

            <h5 className="text-4 mt-4">
              Part V – Your Rights
            </h5>
            <p>
              You have the right to access, update, or delete your personal information. If you have any questions or concerns about our Privacy Policy or the handling of your data, please contact us.
            </p>

            <h5 className="text-4 mt-4">
              Part VI – Changes to This Policy
            </h5>
            <p>
              We may update our Privacy Policy from time to time. Any changes will be posted on this page, and the updated policy will be effective immediately upon posting.
            </p>
            <p>
              By using our website or services, you consent to the terms of this Privacy Policy.
            </p>

            <h3 className="mb-3 mt-4">Workplace Policy</h3>
            <p>
              At Andy Consuting & Co., we embrace a fully remote work environment, allowing us to harness a diverse and talented workforce from across the globe. Our remote model not only supports a flexible and dynamic work culture but also enables us to deliver top-tier engineering services to our clients, no matter where they are located.{" "}
            </p>
            <ol className="lh-lg">
              <li>
                Our primary base of operations is in São Paulo, Brazil, where our team of skilled professionals works collaboratively in a virtual setting. This strategic location allows us to leverage the time zone advantages and cultural synergies to effectively serve the North American market. By operating remotely, we are able to reduce overhead costs, promote work-life balance, and increase productivity, which directly benefits our clients through cost-effective and timely engineering solutions.
              </li>
              <li>
                We are committed to maintaining a high level of communication and collaboration through state-of-the-art technology and project management tools. Regular virtual meetings, clear communication channels, and transparent workflows ensure that our team remains aligned and focused on delivering exceptional results.
              </li>
              <li>
                Our remote work policy emphasizes trust, accountability, and flexibility. We believe in empowering our team members to take ownership of their work while providing the support and resources needed to succeed. By fostering a culture of trust and accountability, we ensure that our clients receive reliable and professional services tailored to their unique needs.
              </li>
              <li>
                Andy is dedicated to transforming technology teams and unlocking the potential of every project we undertake, all while maintaining a remote-first approach that benefits both our employees and our clients.
              </li>
            </ol>

            <p>Last Updated: 06/01/2024</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditionModal;
