import React from "react";

const DisclaimerModal = () => {
  return (
    <div
      id="disclaimer"
      className="modal fade"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Copyright &amp; Disclaimer</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body p-4">
            {/* <h5>Copyright</h5> */}
            <p>
            All content included on this website, such as text, graphics, logos, button icons, images, audio clips, digital downloads, data compilations, and software, is the property of Andy Consulting & Co. or its content suppliers and protected by United States and international copyright laws. The compilation of all content on this site is the exclusive property of Andy Consulting & Co. and protected by U.S. and international copyright laws.{" "}
            </p>
            <h5>Disclaimer</h5>
            <p>
              The information provided on this website is for general informational purposes only. Andy Consulting & Co. makes no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability with respect to the website or the information, products, services, or related graphics contained on the website for any purpose. Any reliance you place on such information is therefore strictly at your own risk. In no event will we be liable for any loss or damage including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the use of this website.
            </p>
            <p>Last Updated: 06/01/2024</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DisclaimerModal;
